<template>
	<div class="caculate_centent" v-loading="loading" element-loading-text="活性胰岛素获取中...">
		<div class="question-title">大剂量向导计算</div>
		<van-form @submit="submit()">
			<van-field
				v-model="BasicInformation.newBloodGlucose"
				required
				name="当前血糖值"
				type="number"
				label="当前血糖值"
				placeholder="建议填写区间：4.0-12.0mmol/L"
				maxlength="5"
				autocomplete="off"
				input-align="right"
				:rules="[{ required: true, message: '请填写当前血糖值', trigger: 'submit' }]"
			/>
			<van-field
				v-model="BasicInformation.targetBloodGlucose"
				required
				name="目标血糖值"
				type="number"
				label="目标血糖值"
				placeholder="建议填写区间：5.0-7.8mmol/L"
				maxlength="5"
				autocomplete="off"
				input-align="right"
				:rules="[{ required: true, message: '请填写目标血糖值', trigger: 'submit' }]"
			/>
			<van-field
				v-model="BasicInformation.iSF"
				required
				name="敏感系数ISF"
				type="number"
				label="敏感系数ISF"
				placeholder="建议填写区间：1.1-5.6mmol/L*U"
				maxlength="5"
				autocomplete="off"
				input-align="right"
				:rules="[{ required: true, message: '请填写敏感系数ISF', trigger: 'submit' }]"
			/>
			<van-field
				v-model="BasicInformation.carbohydrateGrammage"
				required
				name="碳水化合物克数"
				type="number"
				label="碳水化合物克数"
				placeholder="建议填写区间：10-2000grams"
				maxlength="5"
				autocomplete="off"
				input-align="right"
				:rules="[{ required: true, message: '请填写碳水化合物克数', trigger: 'submit' }]"
			/>
			<van-field
				v-model="BasicInformation.iCR"
				required
				name="碳水系数ICR"
				type="number"
				label="碳水系数ICR"
				placeholder="建议填写区间：5-50grams/U"
				maxlength="5"
				autocomplete="off"
				input-align="right"
				:rules="[{ required: true, message: '请填写碳水系数ICR', trigger: 'submit' }]"
			/>
			<van-field
				required
				readonly
				clickable
				name="datetimePicker"
				:value="BasicInformation.activeTime"
				label="活性胰岛素时间"
				placeholder="点击选择时间"
				input-align="right"
				autocomplete="off"
				@click="showPicker = true"
			/>
			<van-field
				v-model="BasicInformation.activeInsulin"
				required
				name="活性胰岛素(U)"
				type="number"
				label="活性胰岛素(U)"
				placeholder="请填写活性胰岛素"
				maxlength="5"
				autocomplete="off"
				input-align="right"
				:rules="[{ required: true, message: '请填写活性胰岛素', trigger: 'submit' }]"
			/>
			<div class="large_dose_button">
				<!-- <van-button @click="reset()" round type="info" color="#ccc">刷新</van-button> -->
				<van-button native-type="submit" round type="info" color="#4B72FE">估算总量</van-button>
			</div>
		</van-form>
		<van-popup v-model="showPicker" position="bottom">
			<van-datetime-picker
				type="time"
				:min-hour="2"
				:max-hour="8"
				:filter="filter"
				@confirm="onConfirm"
				@cancel="showPicker = false"
			/>
		</van-popup>
		<!-- <van-loading v-show="loading" type="spinner" color="#1989fa">活性胰岛素获取中...</van-loading> -->
		<div class="large_dose" v-if="largeDoseShow">计算结果：{{ largeDose }}</div>
		<div class="warning_txt">备注：大剂量向导功能仅适用于微创生命科技V型胰岛素泵</div>
	</div>
</template>

<script>
import Vue from 'vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import { Notify, Loading } from 'vant';
Vue.use(Vant);
Vue.use(Loading);
export default {
	name: 'AddCsiiLifestyleList',
	data() {
		return {
			BasicInformation: {
				//当前血糖值
				newBloodGlucose: '',
				//目标血糖值
				targetBloodGlucose: '',
				//敏感系数
				iSF: '',
				//碳水化合物克数
				carbohydrateGrammage: '',
				//碳水系数
				iCR: '',
				//活性胰岛素
				activeInsulin: '',
				//活性胰岛素时间
				activeTime: ''
			},
			largeDose: null,
			largeDoseShow: false,
			showPicker: false,
			loading: false
		};
	},
	created() {
		// window.getResult = this.getResult;
	},
	mounted() {
		window.nativeCallToSearch = this.nativeCallToSearch;

		this.$jsbridge.registerHandler('nativeCallToSearch', (data) => {
			this.nativeCallToSearch(data);
		});
	},
	methods: {
		filter(type, options) {
			if (type === 'minute') {
				return options.filter((option) => option % 15 === 0);
			}
			return options;
		},
		onConfirm(val) {
			this.BasicInformation.activeTime = val;
			this.showPicker = false;

			this.loading = true;

			// 调用原生接口
			this.getType(val);

			setTimeout(() => {
				if(this.loading === true) {
					this.loading = false;
				}
			}, 1000 * 20);
		},
		getType(val) {
			var u = navigator.userAgent;
			var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android安卓
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios苹果

			// var datas = {};

			if (isAndroid) {
				// window.jsBridge.finis(val);
				window.WebViewJavascriptBridge.callHandler('calInsulin', val, function (response) {
					console.log(response);
					// alert(response);
					// this.nativeCallToSearch(response);
				})
				// JSAndroid.requestPaySupportWithPayWay(val);
			} else if (isiOS) {
				window.webkit.messageHandlers.calInsulin.postMessage(val);
			}
		},
		nativeCallToSearch(res) {
			this.loading = false;

			// 只保留1位小数，直接截取，不四舍五入
			// let retRes = Math.floor(res * 10) / 10;
			// 只保留2位小数，直接截取，不四舍五入
			let retRes = Math.floor(res * 100) / 100;
			this.BasicInformation.activeInsulin = retRes;
		},
		submit() {
			this.largeDoseShow = false;
			let newBloodGlucose = this.BasicInformation.newBloodGlucose;
			let targetBloodGlucose = this.BasicInformation.targetBloodGlucose;
			let iSF = this.BasicInformation.iSF;
			let carbohydrateGrammage = this.BasicInformation.carbohydrateGrammage;
			let iCR = this.BasicInformation.iCR;
			let activeInsulin = this.BasicInformation.activeInsulin;
			let bloodGlucose = (newBloodGlucose - targetBloodGlucose) / iSF;
			let carbohydrate = carbohydrateGrammage / iCR;
			let largeDose = bloodGlucose + carbohydrate - activeInsulin
			// console.log(largeDose.toString().split("."));

			// 只保留1位小数，直接截取，不四舍五入
			if (largeDose.toString().split(".")[1] && largeDose.toString().split(".")[1].length>2) {
				this.largeDose = largeDose.toFixed(1);
			} else {
				this.largeDose = largeDose
			}
			this.largeDose = Math.floor(this.largeDose * 10) / 10;

			// console.log('_______', largeDose);

			// 如果是负数，显示0即可
			if (this.largeDose < 0) {
				this.largeDose = 0;
				Notify({ type: 'warning', message: '当前血糖过低，不适宜输注' });
			}

			this.largeDoseShow = true;

			// console.log('+++++', largeDose);
			// console.log(bloodGlucose);
			// console.log(carbohydrate);
			// console.log(activeInsulin);
			// console.log(bloodGlucose + carbohydrate - activeInsulin);
			// console.log(this.largeDose);
		},
		reset(){
			location.reload();
		}
	}
};
</script>

<style scoped="scoped">
/deep/.van-field__label {
	width: 8rem;
}
.van-button {
	margin-top: 20px;
}
.question-title {
	font-size: 1rem;
	padding: 0 10px;
	height: 2.875rem;
	line-height: 2.875rem;
	border-bottom: 1px dashed #ddd;
	border-top: 1px dashed #ddd;
	background-color: #f8f9fc;
}
/deep/.van-field__label span {
	white-space: pre-line;
}
.large_dose {
	text-align: center;
	padding: 20px 0;
	font-size: 18px;
}
.caculate_centent {
	background-color: #efefef;
}
.large_dose_button{
	margin:0 20px;
	text-align: center;
}
.large_dose_button button{
	/* width: calc(50% - 10px); */
	width: 100%;
}
.large_dose_button button:first-child{
	margin-right: 20px;
}

.warning_txt {
	margin-top: 30px;
	margin-left: 15px;
	color: #646566;
	font-size: 14px;
	line-height: 24px;
}
</style>
